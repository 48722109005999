import { createApp, h } from 'vue'
import App from './App.vue'

import 'vuetify/styles';
import 'vuetify/dist/vuetify.min.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { aliases, mdi } from 'vuetify/iconsets/mdi'

import '@mdi/font/css/materialdesignicons.css'

import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

import moment from 'moment'




import axios from 'axios'
import VueAxios from 'vue-axios'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
})

const app = createApp({
    render() {
        return h(App);
    },
})

// var serverURL = 'http://server.com/travel-app/movers/w360-backend/';
var serverURL = 'https://movers.runbusinesssmartly.com/backend/'; 
app.config.globalProperties.serverAssetURL = serverURL;
app.config.globalProperties.api = serverURL + 'api/';

app.use(vuetify);




import { GoogleMap, Marker } from "vue3-google-map";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueGeolocation from 'vue3-geolocation';
import GMaps from "vuejs3-google-maps";

app.component(GoogleMap, Marker);
app.use(GoogleMap);
app.use(VueGeolocation);

app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB8W_daTpMUGGwuVcXB4xHdm-O1Zf0CYF4",
        libraries: "places",
        componentRestrictions: {country: "us"}
    }
})

app.use(GMaps, {
    load: {
        apiKey: "AIzaSyB8W_daTpMUGGwuVcXB4xHdm-O1Zf0CYF4",
        libraries: ["places"], 
        componentRestrictions: {country: "us"}
    },
});






app.config.globalProperties.moment = moment;

// const api_key = "11fa65aa838255aced7ed865847e9deaa2236c0a92e98c7dfc65ebb37f41b8b5";
const api_key = "b3b920eed126c1b74ef6b86d8873b71961c47d581b40830bedb33a72aa58172d";
const $axios = axios.create({
    // baseURL: `http://server.com/travel-app/movers/w360-backend/api/`, 
    baseURL: `https://movers.runbusinesssmartly.com/backend/api/`,
    headers: {
        'Authorization': 'Bearer ' + api_key,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

app.config.globalProperties.$axios = $axios;
app.config.globalProperties.$api_key = api_key;

app.use(VueAxios, axios);



// app.use(moment)
app.use(VCalendar, {})


app.mount('#app');