<template>
  <v-form>
    <div class="display-flex center-align">
      <v-overlay :model-value="blockUI" class="align-center justify-center">
        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row>
        <div class="w-h-100">
          <div v-if="orderSent">
            <v-row>
              <v-card class="mx-auto text-center" max-width="344">
                <v-card-text>
                  <div>Order received successfully.</div>
                  <p class="text-h4 text--primary">
                    Thank you!
                  </p>
                  <p>Successful</p>
                  <div class="text--primary">
                    Your order has been received and we will contact you shortly.
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="reload()" class="m-t-25px" size="large" :style="btnsucess" block :loading="loading[1]"
                    :disabled="loading[1]" append-icon="mdi mdi-check">OK!
                  </v-btn>
                </v-card-actions>
                <v-card-actions style="flex-direction: column;">
                  <p><strong style="color:red">**NOTE</strong></p>
                  <p>Booking will be confirmed once the deposit has been paid.</p>
                  <a style="width:100%" :href="invoice_link"><v-btn class="m-t-25px" size="large" :style="btnDownload"
                      block :loading="loading[1]" :disabled="loading[1]" append-icon="mdi mdi-arrow-down">Download Invoice
                    </v-btn></a>
                </v-card-actions>
              </v-card>
            </v-row>
          </div>
          <div v-else class="display-flex center-align">
            <div class="form-card display-none-sy">

              <v-alert v-model="validationMessageAlert" style="padding: 20px;" border="start" variant="tonal" closable
                close-label="Close Alert" color="red" title="Errors!">
                <ol tabindex="0" id="errorsList"></ol>
              </v-alert>



              <v-row>

                <div Style="padding: 70px 25px 40px 20px; " class="v-col-sm-12 v-col-md-6 order-last order-md-first">
                  <v-row Style="position: relative !important;" v-if="activeTabSy > 0" :style="topInfoBar">

                    <v-col md="6" sm="6">
                      <v-btn :style="btndanger" class="" size="large" prepend-icon="mdi mdi-arrow-left-circle"
                        @click="back()">Go back</v-btn>
                    </v-col>
                    <v-col md="6" sm="6" class="text-right">
                      <h1 ref="infoBox"></h1>
                    </v-col>

                  </v-row>
                  <v-window :touch="false" v-model="activeTabSy" touchless>
                    <v-window-item>
                      <v-row>
                        <v-col sm="12">
                          <v-row v-if="activeTabSy == 0">
                            <v-col sm="12">
                              <h1 style="text-align:center">Book Now</h1>
                            </v-col>
                          </v-row>
                          <div class="con-tab-ejemplo">
                            <div Style="margin-top: 45px;" class="m-t-25px">
                              <label class="block label-input">Service</label>
                              <v-select v-model="inquiry_form.firstJourney.journey_type" item-title="name" item-value="id"
                                label="Select Service Type" :items="journeyTypes"
                                :loading="journeyTypesLoading"></v-select>
                            </div>
                            <div class="m-t-25px">
                              <label class="block label-input">Pickup<span class="error"
                                  ref="first_journey_start_location"></span></label>
                              <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                <div class="v-field__overlay"></div>
                                <div class="v-field__loader">
                                  <div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true"
                                    aria-valuemin="0" aria-valuemax="100"
                                    style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ">
                                    <!---->
                                    <div class="v-progress-linear__background" style="width: 100%"></div>
                                    <div class="v-progress-linear__indeterminate">
                                      <div class="v-progress-linear__indeterminate long"></div>
                                      <div class="v-progress-linear__indeterminate short"></div>
                                    </div>
                                    <!---->
                                  </div>
                                </div>
                                <div class="map-icon v-field__prepend-inner">
                                  <i class="mdi-map-marker mdi v-icon notranslate v-theme--light v-icon--size-default"
                                    aria-hidden="true"></i><!---->
                                </div>
                                <div class="border-bottom v-progress-linear v-theme--light" role="progressbar"
                                  aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                  style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                                  <!---->
                                  <div class="v-progress-linear__background" style="width: 100%"></div>
                                  <div class="v-progress-linear__indeterminate">
                                    <div class="v-progress-linear__indeterminate long"></div>
                                    <div class="v-progress-linear__indeterminate short"></div>
                                  </div>
                                  <!---->
                                </div>
                                <div class="v-field__field" data-no-activator="">
                                  <label class="v-label v-field-label v-field-label--floating" aria-hidden="true"
                                    for="input-16"><!---->Prepend inner</label><!---->
                                  <GMapAutocomplete type="text" id="input-17" class="v-field__input"
                                    @place_changed="Pick_setPlace" :options="{
                                      componentRestrictions: { country: 'uk' },
                                    }">
                                  </GMapAutocomplete><!---->
                                </div>

                                <div class="v-field__outline">
                                  <!----><!---->
                                </div>
                              </div>
                            </div>
                            <div class="centerx labelx">
                              <div class="m-t-15px">
                                <div v-for="(item, index) in inquiry_form.firstJourney.stops" :key="index"
                                  class="m-t-25px mb_m-t-5px">
                                  <label class="block label-input">
                                    Via {{ index + 1 }}</label>
                                  <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                    <div class="v-field__overlay"></div>
                                    <div class="v-field__loader">
                                      <div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true"
                                        aria-valuemin="0" aria-valuemax="100"
                                        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ">
                                        <!---->
                                        <div class="v-progress-linear__background" style="width: 100%"></div>
                                        <div class="v-progress-linear__indeterminate">
                                          <div class="v-progress-linear__indeterminate long"></div>
                                          <div class="v-progress-linear__indeterminate short"></div>
                                        </div>
                                        <!---->
                                      </div>
                                    </div>
                                    <div class="map-icon v-field__prepend-inner">
                                      <i class="mdi-map-marker-plus mdi v-icon notranslate v-theme--light v-icon--size-default"
                                        aria-hidden="true"></i><!---->
                                    </div>
                                    <div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true"
                                      aria-valuemin="0" aria-valuemax="100"
                                      style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ">
                                      <!---->
                                      <div class="v-progress-linear__background" style="width: 100%"></div>
                                      <div class="v-progress-linear__indeterminate">
                                        <div class="v-progress-linear__indeterminate long"></div>
                                        <div class="v-progress-linear__indeterminate short"></div>
                                      </div>
                                      <!---->
                                    </div>
                                    <div class="v-field__field" data-no-activator="">
                                      <label class="v-label v-field-label v-field-label--floating" aria-hidden="true"
                                        for="input-16"><!---->Prepend inner</label><!---->
                                      <GMapAutocomplete type="text" :value="item.address ? item.address : ''"
                                        :id="'input-first-stops-' + index" class="v-field__input"
                                        @place_changed="stop_setPlace(index, $event)" :options="{
                                          componentRestrictions: { country: 'uk' },
                                        }">
                                      </GMapAutocomplete><!---->
                                    </div>
                                    <div class="v-field__outline">
                                      <!----><!---->
                                    </div>
                                  </div>
                                  <v-btn :style="Remove" class="inline-block-sy myTheme remove-stop" size="x-small"
                                    v-on:click="removeStop(index)">X</v-btn>
                                </div>
                              </div>
                              <div class="m-t-25px">
                                <label class="block label-input">Drop Off <span class="error"
                                    ref="first_journey_destination"></span></label>
                                <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                  <div class="v-field__overlay"></div>
                                  <div class="v-field__loader">
                                    <div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true"
                                      aria-valuemin="0" aria-valuemax="100"
                                      style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    ">
                                      <!---->
                                      <div class="v-progress-linear__background" style="width: 100%"></div>
                                      <div class="v-progress-linear__indeterminate">
                                        <div class="v-progress-linear__indeterminate long"></div>
                                        <div class="v-progress-linear__indeterminate short"></div>
                                      </div>
                                      <!---->
                                    </div>
                                  </div>
                                  <div class="map-icon v-field__prepend-inner">
                                    <i class="mdi-map-marker mdi v-icon notranslate v-theme--light v-icon--size-default"
                                      aria-hidden="true"></i><!---->
                                  </div>
                                  <div class="v-progress-linear v-theme--light" role="progressbar" aria-hidden="true"
                                    aria-valuemin="0" aria-valuemax="100"
                                    style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ">
                                    <!---->
                                    <div class="v-progress-linear__background" style="width: 100%"></div>
                                    <div class="v-progress-linear__indeterminate">
                                      <div class="v-progress-linear__indeterminate long"></div>
                                      <div class="v-progress-linear__indeterminate short"></div>
                                    </div>
                                    <!---->
                                  </div>
                                  <div class="v-field__field" data-no-activator="">
                                    <label class="v-label v-field-label v-field-label--floating" aria-hidden="true"
                                      for="input-16"><!---->Prepend inner</label><!---->
                                    <GMapAutocomplete type="text" id="input-17" class="v-field__input"
                                      @place_changed="drop_setPlace" :options="{
                                        componentRestrictions: { country: 'uk' },
                                      }">
                                    </GMapAutocomplete><!---->
                                  </div>

                                  <div class="v-field__outline">
                                    <!----><!---->
                                  </div>
                                </div>
                                <v-btn :style="add" class="inline-block-sy add-stop" size="x-small"
                                  v-on:click="addStop()">+
                                  <b>Add Stop</b></v-btn>
                              </div>

                            </div>

                            <!-- <div class="m-t-25px">
                                <label class="block label-input">Journey Type</label>
                                <v-select v-model="inquiry_form.firstJourney.journey_type" item-title="name"
                                  item-value="id" label="Select Journey Type" :items="journeyTypes"
                                  :loading="journeyTypesLoading"></v-select>
                              </div>
                              <v-row>
                                <v-col md="6" sm="6">
                                  <div class="m-t-25px">
                                    <label class="block label-input">Pickup Floor</label>
                                    <v-text-field label="Pickup Floor Number" v-model="inquiry_form.pickup_floor"
                                      placeholder="0" min="0" max="100" type="number"></v-text-field>
                                  </div>
                                </v-col>
                                <v-col md="6" sm="6">
                                  <div class="m-t-25px">
                                    <label class="block label-input">Lift on pickup point</label>
                                    <v-select v-model="inquiry_form.pickup_lift" item-title="name" item-value="value"
                                      label="Pickup Lift" :items="trueFalse"></v-select>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col md="6" sm="6">
                                  <div class="m-t-25px">
                                    <label class="block label-input">Dropof Floor Number</label>
                                    <v-text-field label="Dropof Floor Number" v-model="inquiry_form.dropof_floor"
                                      placeholder="0" min="0" max="100" type="number"></v-text-field>
                                  </div>
                                </v-col>
                                <v-col md="6" sm="6">
                                  <div class="m-t-25px">
                                    <label class="block label-input">Lift on dropof point</label>
                                    <v-select v-model="inquiry_form.dropof_lift" item-title="name" item-value="value"
                                      label="Dropof Lift" :items="trueFalse"></v-select>
                                  </div>
                                </v-col>
                              </v-row> -->
                            <!-- <v-switch class="px10 m-t-15px" v-model="inquiry_form.firstJourney.enReturn"
                                    label="Return Journey" color="success" value="success" @click="sunny()"
                                    hide-details></v-switch> -->
                            <v-switch class="px10 m-t-15px" v-model="inquiry_form.is_customer_traveling_along"
                              label="Ride Along" color="success" value="success" hide-details></v-switch>
                            <div>
                              <v-btn class="m-t-25px" size="large" :style="btnsucess" block :loading="loading[1]"
                                :disabled="loading[1]" append-icon="mdi mdi-arrow-right-circle" @click="next(1)">Next
                              </v-btn>
                            </div>
                          </div>
                          <div class="coolText" :style="theme_color_font">
                            <marquee v-if="clientCompanies.length" direction='left' scrollamount='10' height="150px;">
                              <!-- <h4 style="text-align: center;">Book With The Best Movers In Town</h4> -->

                              <img v-for="(company, key) in clientCompanies" :key="key"
                                style="width:120px; height:120px; border: 10px solid #e2e1e1; margin:10px;"
                                :src="serverAssetURL + 'storage/app/public/client_companies/' + company.client_company_logo"
                                :alt="company.name" />

                            </marquee>
                          </div>
                        </v-col>
                      </v-row>
                    </v-window-item>
                    <v-window-item>
                      <v-row>
                        <v-col md="12">
                          <div class="">
                            <VueSlider class="d-none" />
                            <v-row>
                              <!-- <div class="v-col-sm-12 v-col-md-8 ">
                                  <div class="m-t-25px">
                                    <label class="block label-input">Do you need driver help for
                                      loading/offloading?</label>
                                    <v-select v-model="inquiry_form.is_driver_helper" item-title="name" item-value="value"
                                      label="Need Driver Help?" :items="trueFalse"></v-select>
                                  </div>
                                </div> -->
                              <div class="v-col-sm-12 v-col-md-12 ">
                                <div class="m-t-25px">
                                  <label class="block label-input">Extra helpers to get help with loading and
                                    unloading?</label>
                                  <v-text-field label="Helpers" :suffix="helpersSuffix" v-model="inquiry_form.helpers"
                                    placeholder="0" @change="calculatePrice(true);" min="0" max="5"
                                    type="number"></v-text-field>
                                </div>
                              </div>
                            </v-row>
                            <!-- <v-row>
                                <div class="v-col-sm-3 v-col-6">
                                  <div class="m-t-25px text-center">
                                    <p>
                                      Passengers
                                      {{ inquiry_form.passengers }}
                                    </p>
                                    <VueSlider max="64" @mouseup="getVehicles()" v-model="inquiry_form.passengers" />
                                  </div>
                                </div>
                                <div class="v-col-sm-3 v-col-6">
                                  <div class="m-t-25px text-center">
                                    <p>
                                      Suitcases
                                      {{ inquiry_form.suitcases }}
                                    </p>
                                    <VueSlider max="64" @mouseup="getVehicles()" v-model="inquiry_form.suitcases" />
                                  </div>
                                </div>
                                <div class="v-col-sm-3 v-col-6">
                                  <div class="m-t-25px text-center">
                                    <p>
                                      Hand Luggage
                                      {{ inquiry_form.hand_luggage }}
                                    </p>
                                    <VueSlider max="64" @mouseup="getVehicles()" v-model="inquiry_form.hand_luggage" />
                                  </div>
                                </div>
                                <div class="v-col-sm-3 v-col-6">
                                  <div class="m-t-25px text-center">
                                    <p>
                                      Child Seat
                                      {{ inquiry_form.child_seats }}
                                    </p>
                                    <VueSlider max="5" @change="getVehicles()" v-model="inquiry_form.child_seats" />
                                  </div>
                                </div>

                              </v-row> -->
                            <v-row>
                              <v-col lg="12">
                                <div v-if="vehiclesLoading" class="loading">
                                  <v-container style="height: 400px;">
                                    <v-row class="fill-height" align-content="center" justify="center">
                                      <v-col class="text-subtitle-1 text-center" cols="12">
                                        Getting Vehicles ...
                                      </v-col>
                                      <v-col cols="6">
                                        <v-progress-linear color="deep-purple-accent-4" indeterminate rounded
                                          height="6"></v-progress-linear>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </div>
                                <div v-else-if="vans.length">
                                  <Carousel v-model="currentVehicleInSlider" :settings="settings"
                                    :breakpoints="breakpoints" :wrap-around="true">
                                    <template #slides>
                                      <Slide v-for="(  van, key  ) in   vans  " v-bind:key="key">
                                        <div class="car-card">
                                          <div class="display-flex center-align">
                                            <img class="card-img"
                                              :src="serverAssetURL + 'storage/app/public/vehicles/' + van.image" alt="" />
                                          </div>
                                          <div class="card-title text-center">
                                            <h3>{{ vans[key].name }}</h3>
                                            <h5 v-if="vans[key].default_minimum_booking_hours > 0">Minimum Booking :
                                              {{
                                                vans[key].default_minimum_booking_hours }} Hours </h5>
                                            <div v-if="vans[key].calculatedPrice">
                                              <span>Booking From: </span>
                                              <span>
                                                <h2> {{ vans[key].calculatedPrice }} </h2>
                                                <span style="font-size:12px;"> (it is for {{
                                                  vans[key].default_minimum_booking_hours }} hours cost) </span>
                                              </span>
                                            </div>
                                            <div v-else>
                                              <h3>Calculating price...</h3>
                                            </div>
                                          </div>
                                          <div class="align-bootom">
                                            <div class="card-detail text-center">
                                              <p v-if="vans[key].description">{{
                                                vans[key].description.substring(0, 100)
                                              }} {{ vans[key].description.length > 100 ? '...' : '' }}</p>
                                              <!-- <v-chip class="ma-2" variant="text" label
                                                  prepend-icon="mdi-account-multiple">{{
                                                    van.passengers
                                                  }}
                                                  Passenger</v-chip>
                                                <v-chip class="ma-2" variant="text" label
                                                  prepend-icon="mdi-briefcase ">{{
                                                    van.suitcases
                                                  }}
                                                  Suitcases</v-chip>
                                                <v-chip class="ma-2" variant="text" label
                                                  prepend-icon="mdi-briefcase-variant">{{ van.hand_luggage }} Hand
                                                  luggage</v-chip>
                                                <v-chip class="ma-2" variant="text" label
                                                  prepend-icon="mdi-account-multiple">{{
                                                    van.child_seats
                                                  }}
                                                  Child Seats</v-chip> -->
                                            </div>
                                            <div class="display-flex center-align m-t-15px">
                                              <v-btn block :style="btnsucess" @click="
                                                selectVan(vans[key].id), next()
                                                ">Book Now</v-btn>
                                            </div>
                                          </div>
                                        </div>
                                      </Slide>
                                    </template>
                                    <template #addons>
                                      <Pagination />
                                      <Navigation />
                                    </template>
                                  </Carousel>
                                </div>
                                <div v-else class="no-data">
                                  <h4>No Vehicle Found!</h4>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </v-window-item>
                    <v-window-item>
                      <v-card>
                        <v-tabs :style="deliverableItemsTabs" v-model="deliverable_items_current_tab" show-arrows
                          slider-color="teal-lighten-3">
                          <span v-for="(  deliverableCategory, index  ) in   Object.keys(inquiry_form.deliverableItems)  "
                            :key="index">
                            <v-tab v-if="index > 0"> {{ deliverableCategory }}
                            </v-tab></span>
                        </v-tabs>
                        <v-card-text>
                          <v-window v-model="deliverable_items_current_tab">
                            <span
                              v-for="(  deliverableCategory, index  ) in    Object.keys(inquiry_form.deliverableItems)  "
                              :key="index">
                              <v-window-item v-if="index > 0">
                                <v-table density="compact">
                                  <thead class="tabel_heading">
                                    <tr>
                                      <th class="text-left">
                                        {{ deliverableCategory }}
                                      </th>
                                      <th class="text-left">

                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody class="table_list">
                                    <tr v-for="(  item, key  ) in   inquiry_form.deliverableItems[deliverableCategory]  "
                                      :key="key">
                                      <th>{{ item.name }}</th>
                                      <td style="padding:0">

                                        <v-text-field label="Quantity"
                                          v-model="inquiry_form.deliverableItems[deliverableCategory][key].qty"
                                          placeholder="0" min="0" max="100" type="number"></v-text-field>

                                      </td>
                                    </tr>
                                  </tbody>
                                </v-table>
                              </v-window-item>
                            </span>
                          </v-window>
                        </v-card-text>
                      </v-card>
                      <v-row>
                        <v-col>
                          <div class="m-t-15px">

                          </div>


                        </v-col>
                      </v-row>
                      <v-card max-height="200px" class="overflow-auto"
                        v-if="inquiry_form.deliverableItems['extra_items']">
                        <v-card-text>
                          <h3>Add Items (if not listed above)</h3>
                          <v-table density="compact">

                            <thead class="tabel_heading p-0">
                              <tr>
                                <td class="width_td_items">

                                  <v-text-field type="text" id="Item_Name" name="Extra_item" placeholder="Item"
                                    v-model="inquiry_form.deliverableItems['extra_items'][0].name" />

                                </td>
                                <td class="width_td_qty">

                                  <v-text-field label="QTY" v-model="inquiry_form.deliverableItems['extra_items'][0].qty"
                                    placeholder="0" min="0" max="100" type="number"></v-text-field>

                                </td>
                                <td class="text-right">
                                  <v-btn :style="add" class="custome-btn" size="small" v-on:click="addItem()">+</v-btn>
                                </td>
                              </tr>
                            </thead>

                          </v-table>
                          <div v-for="(  item, index  ) in   inquiry_form.deliverableItems['extra_items']  " :key="index"
                            class="mb_m-t-5px">
                            <v-table v-if="index > 0" density="compact">

                              <thead class="tabel_heading p-0">
                                <tr>
                                  <td class="width_td_items">


                                    <v-text-field type="text" :id="'input-items-items-' + index"
                                      :name="'Extra_item' + index" :placeholder="' Item ' + (index)"
                                      v-model="inquiry_form.deliverableItems['extra_items'][index].name" />
                                  </td>
                                  <td class="width_td_qty">

                                    <v-text-field label="QTY" :id="'input-items-Qty-' + index"
                                      v-model="inquiry_form.deliverableItems['extra_items'][index].qty" placeholder="0"
                                      min="0" max="100" type="number"></v-text-field>

                                  </td>
                                  <td class="text-right">
                                    <v-btn :style="Remove" class="" size="small" v-on:click="removeItem(index)">X</v-btn>
                                  </td>
                                </tr>

                              </thead>
                            </v-table>

                          </div>
                        </v-card-text>
                      </v-card>
                      <v-row>
                        <v-col md="6" sm="6">
                          <div class="m-t-25px">
                            <label class="block label-input">Pickup Floor</label>
                            <v-text-field label="Pickup Floor Number" v-model="inquiry_form.pickup_floor" placeholder="0"
                              min="0" max="100" type="number"></v-text-field>
                          </div>
                        </v-col>
                        <v-col md="6" sm="6">
                          <div class="m-t-25px">
                            <label class="block label-input">Lift at pickup point</label>
                            <v-select v-model="inquiry_form.pickup_lift" item-title="name" item-value="value"
                              label="Pickup Lift" :items="trueFalse"></v-select>
                          </div>
                        </v-col>


                      </v-row>
                      <v-row>
                        <v-col md="6" sm="6">
                          <div class="">
                            <label class="block label-input">Drop of Floor Number</label>
                            <v-text-field label="Dropof Floor Number" v-model="inquiry_form.dropof_floor" placeholder="0"
                              min="0" max="100" type="number"></v-text-field>
                          </div>
                        </v-col>
                        <v-col md="6" sm="6">
                          <div class="">
                            <label class="block label-input">Lift at drop of point</label>
                            <v-select v-model="inquiry_form.dropof_lift" item-title="name" item-value="value"
                              label="Dropof Lift" :items="trueFalse"></v-select>
                          </div>
                        </v-col>


                      </v-row>
                      <div class="m-t-25px">
                        <label class="block label-input" for="pich_date_time">Items list or notes <span class="error"
                            ref="first_journey_pi_date"></span></label>
                        <v-textarea
                          label="E.g Assembling and disassembling required, Parking availability, hight restrictions, small/narrow lift"
                          v-model="inquiry_form.notes"></v-textarea>
                      </div>
                      <div class="m-t-25px">
                        <h3>How many hours do you need this for?</h3>
                        <!-- <p>Non Booked over time will cost {{ companyInfo['company_currency_code'] }} 20.1 booked in
                          advance your total time and save upto 30%
                        </p> -->
                      </div>
                      <v-table density="compact">

                        <thead class="tabel_heading p-0">
                          <tr>
                            <td class="" v-if="this.inquiry_form.firstJourney.calculations">
                              <h5>Driving Time {{ this.inquiry_form.firstJourney.calculations[0].time }}</h5>
                            </td>
                            <td class="">
                              <label class="block label-input">Select Extra Time</label>
                              <v-select label="Extra Hours" :items="inquiry_form.extra_hoursItems"
                                v-model="inquiry_form.extra_hours" placeholder="0"
                                @change="calculatePrice(true)"></v-select>
                            </td>

                          </tr>
                        </thead>

                      </v-table>


                      <v-btn class="m-t-25px" size="large" :style="btnsucess" block :loading="loading[1]"
                        :disabled="loading[1]" append-icon="mdi mdi-arrow-right-circle" @click="next(1)">Next
                      </v-btn>
                    </v-window-item>
                    <v-window-item>
                      <v-row sm="12">
                        <v-col md="12" class="">
                          <div class="">
                            <div class="">
                              <label style="color:red" class="block label-input"><strong>NOTE* </strong>Booking before
                                {{
                                  selectedVan[0].default_minimum_booking_hours }} hours will be
                                considered as express booking and will be charged for {{
                                  companyInfo['company_currency_code'] }} {{ selectedVan[0].urgent_booking_charges
  }}</label>
                              <label class="block label-input" for="pich_date_time">Pickup date & time <span class="error"
                                  ref="first_journey_pi_date"></span></label>
                              <v-row>
                                <v-col class="no-padding" md="12" sm="12">
                                  <v-date-picker :min-date="new Date()" v-model="inquiry_form.pi_date"
                                    :model-config="modelConfig">
                                    <template v-slot="{ inputValue, inputEvents, }">
                                      <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                        <div class="v-field__overlay"></div>
                                        <div class="v-field__loader">
                                          <div class="v-progress-linear v-theme--light" role="progressbar"
                                            aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                            style=" height: 0px; --v-progress-linear-height: 2px;  ">
                                            <!---->
                                            <div class="v-progress-linear__background" style="width: 100%">
                                            </div>
                                            <div class="v-progress-linear__indeterminate">
                                              <div class="v-progress-linear__indeterminate long"></div>
                                              <div class="v-progress-linear__indeterminate short"></div>
                                            </div>
                                            <!---->
                                          </div>
                                        </div>
                                        <div class="map-icon v-field__prepend-inner">
                                          <i class="mdi-clock-fast mdi v-icon notranslate v-theme--light v-icon--size-default"
                                            aria-hidden="true"></i><!---->
                                        </div>
                                        <div class="v-progress-linear v-theme--light" role="progressbar"
                                          aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                          style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ">
                                          <!---->
                                          <div class="v-progress-linear__background" style="width: 100%">
                                          </div>
                                          <div class="v-progress-linear__indeterminate">
                                            <div class="v-progress-linear__indeterminate long"></div>
                                            <div class="v-progress-linear__indeterminate short"></div>
                                          </div>
                                          <!---->
                                        </div>
                                        <div class="v-field__field" data-no-activator="">
                                          <label class="v-label v-field-label v-field-label--floating" aria-hidden="true"
                                            for="input-16"><!---->Prepend
                                            inner</label><!---->
                                          <input id="input-17" class="v-field__input" :value="inputValue"
                                            v-on="inputEvents" />
                                        </div>
                                        <div class="v-field__outline">
                                          <!----><!---->
                                        </div>
                                      </div>
                                    </template>
                                  </v-date-picker>
                                </v-col>
                              </v-row>
                            </div>

                            <div class="m-t-25px">
                              <label class="block label-input" for="pich_date_time">Time Range: {{
                                inquiry_form.timeRangeDisplay }}
                                <span v-if="calculatingFixedTimeArrivalPriceLoading">
                                  calculating...
                                </span>
                                <span v-else-if="calculatedFixedTimeArrivalPrice"><strong>+{{
                                  calculatedFixedTimeArrivalPrice }}</strong></span></label>
                              <VueSlider v-model="inquiry_form.timeRange" @change="(v) => updateTimeSlide(v)"
                                :enableCross="false" :minRange="4" :max-Range="4" :min="8" :max="18" />

                            </div>
                            <div class="m-t-25px">
                              <label class="block label-input" for="Contact_Name">Contact Name <span class="error"
                                  ref="client_name"></span></label>
                              <v-text-field type="text" id="Contact_Name" name="name" prepend-inner-icon="mdi mdi-account"
                                required placeholder="Name" v-model="inquiry_form.name" />
                            </div>
                            <div class="m-t-25px">
                              <label class="block label-input" for="Contact_Email">Contact Email <span class="error"
                                  ref="client_email"></span></label>
                              <v-text-field type="email" id="Contact_Email" name="email"
                                prepend-inner-icon="mdi-email-multiple" required placeholder="Email"
                                v-model="inquiry_form.email" />
                            </div>
                            <div class="m-t-25px">
                              <label class="block label-input" for="phone_number">Mobile Number <span class="error"
                                  ref="client_mobile"></span></label>
                              <v-text-field type="tel" id="phone_number" name="name" prepend-inner-icon="mdi-cellphone"
                                required placeholder="Phone" v-model="inquiry_form.phone_number" />
                            </div>

                            <v-btn class="m-t-25px" :style="btnsucess" size="large" block
                              append-icon="mdi mdi-email-arrow-right" @click="saveAndSendOrder()">Send Inquiry</v-btn>
                            <!-- <v-btn class="m-t-15px book-now" size="large" :style="btnsucess" block
                                append-icon="mdi mdi-arrow-right-circle" @click="next()">Book & Pay Now
                              </v-btn> -->
                          </div>
                        </v-col>
                      </v-row>
                    </v-window-item>

                    <v-window-item>
                      <v-row sm="12">
                        <v-col md="12">
                          <div class="">
                            <div v-if="this.paymentMethod == 2">
                              <div class="">
                                <label class="block label-input" for="Card_Number">Card No.</label>
                                <v-text-field type="number" min="00000000" max="9999999999999999999" id="Card_Number"
                                  name="Card_Number" prepend-inner-icon="mdi-credit-card-outline" required
                                  placeholder="Card Number" v-model="inquiry_form.cardDetails.cardNo" />
                              </div>
                              <v-row>
                                <v-col>
                                  <div class="m-t-25px">
                                    <label class="block label-input" for="phone_number">CVC</label>
                                    <v-text-field type="number" id="cvc_number" min="000" max="999" name="cvc"
                                      prepend-inner-icon="mdi-lock-check" reqcvcuired placeholder="Phone"
                                      v-model="inquiry_form.cardDetails.cvc" />
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="m-t-25px">
                                    <label class="block label-input" for="phone_number">Expiary Date</label>
                                    <v-date-picker v-model="inquiry_form.cardDetails.expiary
                                      " :min-date="new Date()" :max-date="new Date(2028, 2, 1)">
                                      <template v-slot="{ inputValue, inputEvents }">
                                        <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                          <div class="v-field__overlay"></div>
                                          <div class="v-field__loader">
                                            <div class="v-progress-linear v-theme--light" role="progressbar"
                                              aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                              style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ">
                                              <!---->
                                              <div class="v-progress-linear__background" style="width: 100%"></div>
                                              <div class="v-progress-linear__indeterminate">
                                                <div class="v-progress-linear__indeterminate long"></div>
                                                <div class="v-progress-linear__indeterminate short"></div>
                                              </div>
                                              <!---->
                                            </div>
                                          </div>
                                          <div class="map-icon v-field__prepend-inner">
                                            <i class="mdi mdi-calendar-clock-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                                              aria-hidden="true"></i><!---->
                                          </div>
                                          <div class="v-progress-linear v-theme--light" role="progressbar"
                                            aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                            style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ">
                                            <!---->
                                            <div class="v-progress-linear__background" style="width: 100%"></div>
                                            <div class="v-progress-linear__indeterminate">
                                              <div class="v-progress-linear__indeterminate long"></div>
                                              <div class="v-progress-linear__indeterminate short"></div>
                                            </div>
                                            <!---->
                                          </div>
                                          <div class="v-field__field" data-no-activator="">
                                            <label class="v-label v-field-label v-field-label--floating"
                                              aria-hidden="true" for="input-16"><!---->Prepend inner</label><!---->
                                            <input id="input-17" class="v-field__input" :value="inputValue"
                                              v-on="inputEvents" />
                                          </div>
                                          <div class="v-field__outline">
                                            <!----><!---->
                                          </div>
                                        </div>
                                      </template>
                                    </v-date-picker>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="this.paymentMethod == 3">
                              <div class="">
                                <label class="block label-input" for="Card_Number">Card No.</label>
                                <v-text-field type="number" min="00000000" max="9999999999999999999" id="Card_Number"
                                  name="Card_Number" prepend-inner-icon="mdi-credit-card-outline" required
                                  placeholder="Card Number" v-model="inquiry_form.cardDetails.cardNo" />
                              </div>
                              <v-row>
                                <v-col>
                                  <div class="m-t-25px">
                                    <label class="block label-input" for="phone_number">CVC</label>
                                    <v-text-field type="number" id="cvc_number" min="000" max="999" name="cvc"
                                      prepend-inner-icon="mdi-lock-check" reqcvcuired placeholder="Phone"
                                      v-model="inquiry_form.cardDetails.cvc" />
                                  </div>
                                </v-col>
                                <v-col>
                                  <div class="m-t-25px">
                                    <label class="block label-input" for="phone_number">Expiary Date</label>
                                    <v-date-picker v-model="inquiry_form.cardDetails.expiary
                                      " :min-date="new Date()" :max-date="new Date(2028, 2, 1)">
                                      <template v-slot="{ inputValue, inputEvents }">
                                        <div class="v-field v-field--prepended v-field--variant-filled v-theme--light">
                                          <div class="v-field__overlay"></div>
                                          <div class="v-field__loader">
                                            <div class="v-progress-linear v-theme--light" role="progressbar"
                                              aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                              style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ">
                                              <!---->
                                              <div class="v-progress-linear__background" style="width: 100%"></div>
                                              <div class="v-progress-linear__indeterminate">
                                                <div class="v-progress-linear__indeterminate long"></div>
                                                <div class="v-progress-linear__indeterminate short"></div>
                                              </div>
                                              <!---->
                                            </div>
                                          </div>
                                          <div class="map-icon v-field__prepend-inner">
                                            <i class="mdi mdi-calendar-clock-outline mdi v-icon notranslate v-theme--light v-icon--size-default"
                                              aria-hidden="true"></i><!---->
                                          </div>
                                          <div class="v-progress-linear v-theme--light" role="progressbar"
                                            aria-hidden="true" aria-valuemin="0" aria-valuemax="100"
                                            style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        height: 0px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        --v-progress-linear-height: 2px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ">
                                            <!---->
                                            <div class="v-progress-linear__background" style="width: 100%"></div>
                                            <div class="v-progress-linear__indeterminate">
                                              <div class="v-progress-linear__indeterminate long"></div>
                                              <div class="v-progress-linear__indeterminate short"></div>
                                            </div>
                                            <!---->
                                          </div>
                                          <div class="v-field__field" data-no-activator="">
                                            <label class="v-label v-field-label v-field-label--floating"
                                              aria-hidden="true" for="input-16"><!---->Prepend inner</label><!---->
                                            <input id="input-17" class="v-field__input" :value="inputValue"
                                              v-on="inputEvents" />
                                          </div>
                                          <div class="v-field__outline">
                                            <!----><!---->
                                          </div>
                                        </div>
                                      </template>
                                    </v-date-picker>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                            <a href="https://www.paypal.com/" target="_blank"><v-btn class="m-t-15px margin-t-10px paypal"
                                size="large" :style="btnsucess" block append-icon="mdi mdi-wallet-outline"
                                @click="setPaymentMethod('1')">
                                <v-radio v-model="paymentMethod" label="" value="1"></v-radio>Pay Through Paypal
                              </v-btn></a>
                            <v-btn class="m-t-15px bank-transfer" size="large" :style="btnsucess" block
                              append-icon="mdi mdi-bank" @click="setPaymentMethod('2')">
                              <v-radio v-model="paymentMethod" label="" value="2"></v-radio>Bank Transfer
                            </v-btn>
                            <v-btn class="m-t-15px credit-card" size="large" :style="btnsucess" block
                              append-icon="mdi mdi-credit-card-outline" @click="setPaymentMethod('3')">
                              <v-radio v-model="paymentMethod" label="" value="3"></v-radio>Credit Card
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-window-item>
                  </v-window>
                </div>
                <v-col class=" padding overflow-y-scroll" md="6" sm="12" order="last" :style="theme_color">
                  <div class="d-none d-md-block bg-img-tab-1" v-if="activeTabSy == 0">
                    <!-- <img src="../assets/img/bakaavans.png" style="width:100%" /> -->
                  </div>

                  <v-expansion-panels class="m-t-25px" v-model="smPanel" density="compact" color="green">

                    <v-expansion-panel v-if="activeTabSy > 0">
                      <v-expansion-panel-title @click="loadRoutesMapView()">
                        <h3>Journey Details</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div id="routesMapViewContainer"></div>
                        <hr />
                        <v-table>
                          <tbody>
                            <tr v-if="this.inquiry_form.firstJourney.calculations">
                              <td>Distance</td>
                              <td>{{ this.inquiry_form.firstJourney.calculations[0].distance }}</td>
                            </tr>
                            <tr v-if="this.inquiry_form.firstJourney.calculations">
                              <td>Time</td>
                              <td>{{ this.inquiry_form.firstJourney.calculations[0].time }}</td>
                            </tr>
                            <tr>
                              <td>Pickup</td>
                              <td>
                                {{
                                  inquiry_form.firstJourney
                                    .start_location.address
                                }}
                              </td>
                            </tr>
                            <tr v-for="(  item, index  ) in 
  
                                                      inquiry_form
                                                            .firstJourney.stops

                                                    " :key="index">
                              <td>Additional Stop: {{ index + 1 }}</td>
                              <td>
                                {{
                                  inquiry_form.firstJourney.stops[
                                    index
                                  ].address
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Drop Off</td>
                              <td>
                                {{
                                  inquiry_form.firstJourney
                                    .destination.address
                                }}
                              </td>
                            </tr>
                            <!-- <tr>
                                <td>Pickup date & time</td>
                                <td>
                                  {{
                                    moment(
                                      inquiry_form.
                                        .pi_date
                                                                    ).format(
                                          "ddd MMM DD, YYYY [at] HH: mm"
                                        )
                                  }}
                                </td>
                              </tr> -->
                            <!-- <tr>
                                <td>Service Type</td>
                                <td>
                                  {{
                                    inquiry_form.firstJourney.journey_type ? inquiry_form.firstJourney.journey_type : 'N/A'
                                  }}
                                </td>
                              </tr> -->
                            <tr>
                              <td>Ride Along</td>
                              <td>
                                {{
                                  inquiry_form.is_customer_traveling_along ? 'Yes' : 'No'
                                }}
                              </td>
                            </tr>
                            <!-- <tr>
                                <td>Pickup Floor</td>
                                <td>
                                  {{
                                    inquiry_form.pickup_floor
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Pickup Lift</td>
                                <td>
                                  {{
                                    inquiry_form.pickup_lift ? 'Yes' : 'No'
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Dropof Floor</td>
                                <td>
                                  {{
                                    inquiry_form.dropof_floor
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Dropof Lift</td>
                                <td>
                                  {{
                                    inquiry_form.dropof_lift ? 'Yes' : 'No'
                                  }}
                                </td>
                              </tr> -->


                          </tbody>
                        </v-table>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="activeTabSy > 1">
                      <v-expansion-panel-title>
                        <h3>Vehical Details</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-table>
                          <tbody>
                            <!-- <tr>
                                <td>Passengers</td>
                                <td>{{ inquiry_form.passengers }}</td>
                              </tr>
                              <tr>
                                <td>Suitcases</td>
                                <td>{{ inquiry_form.suitcases }}</td>
                              </tr>
                              <tr>
                                <td>Hand Luggage</td>
                                <td>{{ inquiry_form.hand_luggage }}</td>
                              </tr>
                              <tr>
                                <td>Child Seats</td>
                                <td>{{ inquiry_form.child_seats }}</td>
                              </tr> -->
                            <!-- <tr>
                                <td>Driver help will be required? </td>
                                <td>{{ inquiry_form.is_driver_helper ? 'Yes' : 'No' }}</td>
                              </tr> -->
                            <tr>
                              <td>Extra Helpers</td>
                              <td>{{ inquiry_form.helpers }}</td>
                            </tr>
                            <tr>
                              <div class="m-t-25px" v-if="selectedVan[0]">
                                <v-row>
                                  <v-col col="12" md="12" lg="12" sm="12">
                                    <div class="display-flex center-align">
                                      <!-- <img class="card-img" src="../assets/img/one.jpg" alt="" /> -->
                                      <img class="card-img"
                                        :src="serverAssetURL + 'storage/app/public/vehicles/' + selectedVan[0].image"
                                        alt="" />
                                    </div>
                                  </v-col>
                                  <v-col col="12">
                                    <div class="card-title text-center mn-t-25px">
                                      <h3 class="mt-3">
                                        {{ selectedVan[0].name }}
                                      </h3>
                                      <h5 v-if="selectedVan[0].default_minimum_booking_hours > 0">Minimum Booking : {{
                                        selectedVan[0].default_minimum_booking_hours }} Hours</h5>
                                      <span>Booking From:</span>
                                      <h2 v-if="selectedVan[0].calculatedPrice"> {{ selectedVan[0].calculatedPrice }}
                                      </h2>

                                      <p>
                                        {{ selectedVan[0].detail }}
                                      </p>
                                    </div>
                                  </v-col>

                                </v-row>
                              </div>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="activeTabSy > 2">
                      <v-expansion-panel-title>
                        <h3>Deliverable Items</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <span v-if="Object.keys(inquiry_form.deliverableItems).length > 0">
                          <span v-for=" (  categoryItems, index  ) in   inquiry_form.deliverableItems   " :key="index">
                            <span v-if="categoryItems.length">
                              <span v-for=" (  item, key  ) in   categoryItems   " :key="key">
                                <v-row style="margin-top: 10px;" v-if="item.qty">
                                  <v-col class="div-col">{{ item.name }}</v-col>
                                  <v-col class="div-col"> {{ item.qty }} </v-col>
                                </v-row>
                              </span>
                            </span>
                          </span>
                        </span>
                        <h3 style="margin-top: 35px;">Pickup/Dropof Information</h3>
                        <v-row style="margin-top: 10px;">
                          <v-col class="div-col">Pickup Floor</v-col>
                          <v-col class="div-col"> {{ inquiry_form.pickup_floor }} </v-col>
                        </v-row>
                        <v-row style="margin-top: 10px;">
                          <v-col class="div-col">Pickup Lift</v-col>
                          <v-col class="div-col"> {{ inquiry_form.pickup_lift ? 'Yes' : 'No' }} </v-col>
                        </v-row>
                        <v-row style="margin-top: 10px;">
                          <v-col class="div-col">Dropof Floor</v-col>
                          <v-col class="div-col"> {{ inquiry_form.dropof_floor }} </v-col>
                        </v-row>
                        <v-row style="margin-top: 10px;">
                          <v-col class="div-col">Dropof Lift</v-col>
                          <v-col class="div-col"> {{ inquiry_form.dropof_lift ? 'Yes' : 'No' }} </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="activeTabSy > 3">
                      <v-expansion-panel-title>
                        <h3>Contact details</h3>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-table>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>{{ inquiry_form.name }}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{{ inquiry_form.email }}</td>
                            </tr>
                            <tr>
                              <td>Mobile Number</td>
                              <td>{{ inquiry_form.phone_number }}</td>
                            </tr>
                            <tr>
                              <td>Pickup date & time</td>
                              <td>
                                {{
                                  moment(
                                    inquiry_form.pi_date
                                  ).format(
                                    "ddd MMM DD, YYYY [at] HH: mm"
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Time Range</td>
                              <td>{{ this.inquiry_form.timeRangeDisplay }}</td>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div class="testimonial">
                    <!-- <h1 style="text-align:center;     color: white;">Reviews</h1> -->
                    <!-- <div class="separator"></div> -->
                    <!-- <h2 style="text-align: center; color: #ffffff; font-size: 24px; margin-bottom: -20px; padding: 0px;">  Reviews</h2> -->
                    <Carousel :breakpoints="testimonialBreakpoints" :items-to-show="2.1" :transition="500"
                      :wrap-around="true">
                      <Slide v-for=" (review, key)  in  reviews" :key="key">
                        <div class="super_div">
                          <div class="div_testimonial"
                            Style="margin:10px 10px 30px 10px; min-height: 120px !important;    min-width: 350px; max-width:350px;    padding: 10px 15px;">
                            <div class="testimonial ">
                              <v-row>
                                <!-- <v-col md="5">
                                  <img v-if="review.profile_pic" decoding="async" loading="lazy"
                                    class="profile_image testimonial_img"
                                    :src="serverAssetURL + 'storage/app/public/reviews/' + review.profile_pic"
                                    alt="user pic" width="80" height="80">
                                  <img v-else decoding="async" loading="lazy" class="profile_image testimonial_img"
                                    :src="serverAssetURL + 'storage/app/public/defaults/default.png'" alt="user pic"
                                    width=" 80" height="80">
                                </v-col> -->
                                <v-col md="7" lg="7" sm="6" class="vertical-middle d-flex">
                                  <h3>{{ review.name }}</h3>
                                </v-col>
                                <v-col md="5" lg="5" sm="6" class="vertical-middle d-flex">
                                  <div class="startrations">
                                    <span v-for="ratingStar in 5" :key="ratingStar">
                                      <span v-if="ratingStar <= review.rating" :style="theme_color_font"
                                        class="mdi mdi-star"></span>
                                      <span v-else :style="theme_color_offset" class="mdi mdi-star"></span>
                                    </span>
                                    <span><img class="review-company-logo" src="google-review.png" /></span>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row style="margin: 5px -12px;">
                                <v-col md="12" lg="12" style="padding:0px 10px;">
                                  <div class=" md-col:12 lg-col:12">
                                    <p class="fixed-content">{{ review.description }}</p>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                      </Slide>
                      <template #addons>
                        <Navigation />
                      </template>
                    </Carousel>
                  </div>
                  <div class="testimonial">
                    <div class="masonry">
                      <div class="masonry-item">
                        <img src="../assets/img/1.png" alt="Image 1">
                      </div>
                      <!-- <div class="masonry-item">
                        <img src="../assets/img/2.png" alt="Image 2">
                      </div> -->
                      <div class="masonry-item">
                        <img src="../assets/img/3.png" alt="Image 3">
                      </div>
                      <div class="masonry-item">
                        <img src="../assets/img/4.png" alt="Image 4">
                      </div>
                      <!-- <div class="masonry-item">
                        <img src="../assets/img/5.png" alt="Image 5">
                      </div> -->
                      <div class="masonry-item">
                        <img src="../assets/img/6.png" alt="Image 6">
                      </div>

                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </v-form>
</template>

<script>

import VueSlider from "vue-3-slider-component";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Form from "vform";



export default {


  name: "MultiStep",
  components: {
    Carousel,
    Slide,
    VueSlider,
    Pagination,
    Navigation,
  },

  computed: {
    Remove() {
      return {
        "background-color": this.bgColor_D,
        'color': this.color,
        'font-size': '18px',
        'padding': '15px',
        'line-height': '1px',
      };
    },
    add() {
      return {
        "background-color": this.bgColor,
        'color': this.color,
        'font-size': '30px',
        'padding': '28px',
        'line-height': '1px',
      };
    },
    deliverableItemsTabs() {
      return {
        "background-color": this.bgColor,
        'color': this.color,
      };
    },
    btnDownload() {
      return {
        "background-color": '#800055',
        color: this.color,
        "font-size": this.ft_20,
        height: this.height,
      };
    },
    btnsucess() {
      return {
        "background-color": this.bgColor,
        color: this.color,
        "font-size": this.ft_20,
        height: this.height,
      };
    },
    theme_color() {
      return {
        "background-color": this.bgColor,
      };
    },
    theme_color_font() {
      return {
        color: this.bgColor,
      };
    },
    theme_color_offset() {
      return {
        color: '#ccc',
      };
    },
    btndanger() {
      return {
        "background-color": this.bgColor_D,
        color: this.color,
        "font-size": this.ft_20,
        height: this.height_D,
      };
    },
    topInfoBar() {
      return {
        "display": "flex",
        // "position": "fixed",
        "margin-top": "-38px",
        "z-index": 10,
        "width": "100%",
        "padding": "20px 15px",
        "margin-bottom": "20px",
        "background": "white",
      }
    }
  },
  data: () => ({
    deliverable_items_current_tab: 0,
    d_none_sy: true,
    invoice_link: '',
    estimated_price: '',
    helpersSuffix: '',
    d_none_bank: true,
    formValidation: false,
    validationMessageAlert: false,
    orderSent: false,
    blockUI: false,
    calculatingFixedTimeArrivalPriceLoading: false,
    calculatedFixedTimeArrivalPrice: 0,
    // carousel settings
    bgColor_D: "red",
    height_D: "50px",
    height: "60px",
    ft_20: "20px",
    bgColor: "green",
    color: "white",
    tab2: "one_way",
    slider3: 50,
    panel: [],
    smPanel: 5,
    // modelValue:60,
    theme_color_gradient_right: 'linear-gradient(90deg, green, #00800000)',
    theme_color_gradient_left: 'linear-gradient(270deg, green, #00800000)',
    loading: [],
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    trueFalse: [{ value: true, name: 'Yes' }, { value: false, name: 'No' }],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    colors: [
      "green",
      "secondary",
      "yellow darken-4",
      "red lighten-2",
      "orange darken-1",
    ],
    paymentMethod: "2",
    requiredFields: [
      {
        tab: 0,
        fields: [
          { field: 'firstJourney.start_location', label: 'Start Location', errorSpanRef: 'first_journey_start_location' },
          { field: 'firstJourney.destination', label: 'Destination', errorSpanRef: 'first_journey_destination' },
        ]
      },
      {
        tab: 1,
        fields: [

        ]
      },
      {
        tab: 2,
        fields: [

        ]
      },
      {
        tab: 3,
        fields: [
          { field: 'name', label: 'Name', errorSpanRef: 'client_name' },
          { field: 'email', label: 'Email', errorSpanRef: 'client_email' },
          { field: 'phone_number', label: 'Mobile', errorSpanRef: 'client_mobile' },
          { field: 'pi_date', label: 'Pickup Date', errorSpanRef: 'first_journey_pi_date' },
        ]
      }

    ],
    inquiry_form: new Form({
      api_key: '',
      pickup_floor: 0,
      pickup_lift: false,
      dropof_floor: 0,
      dropof_lift: false,
      helpers: 0,
      is_driver_helper: false,
      extra_hours: 0,
      extra_hoursItems: [1, 2, 3, 4, 5,],
      is_customer_traveling_along: false,
      firstJourney: {
        start_location: "",
        stops: [],
        destination: "",
        journey_type: 0,
        enReturn: false,
      },
      deliverableItems: [],
      vehicle_type_id: 0,
      van: 0,
      cardDetails: {
        cardNo: [],
        cvc: [],
        expiary: Date([]),
      },

      child_seats: 0,
      e1: 1,
      secondary: "rgb(31,116,255)",
      passengers: 0,
      suitcases: 0,
      hand_luggage: 0,

      // Step 3
      pi_date: '',// new Date(),
      timeRange: [8, 12],
      name: "",
      email: "",
      phone_number: "",
      notes: "",
    }),
    error: [],
    selectedVan: 0,
    vans: [],
    companyInfo: [],
    reviews: [],
    clientCompanies: [],
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      1250: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      1600: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      2000: {
        itemsToShow: 1,
        snapAlign: "start",
      },
    },
    testimonialBreakpoints: {
      10: {
        itemsToShow: 1,
      },
      768: {
        itemsToShow: 2,
      },
      1024: {
        itemsToShow: 1.3,
      },
      1250: {
        itemsToShow: 1.5,
      },
      1440: {
        itemsToShow: 2,
      },
      1600: {
        itemsToShow: 3,
      },
      2000: {
        itemsToShow: 4,
      },
    },
    calculated_distance: 0,
    activeTabSy: 0,
    checkBox1: false,
    colorx: "#103767",
    blue: "#1F74FF",
    white: "#103767",
    colorx2: "#5252e8",
    journeyTypes: [],
    journeyTypesLoading: false,
    vehicles: [],
    vehiclesLoading: false,
    ReviewsLoading: false,
    currentVehicleInSlider: '',
  }),
  mounted() {
    this.getCompanyInfo();
    this.getJourneyTypes();
    this.getDeliverableItems();
    this.getReviews();
    this.getClientCompanies();

    setTimeout(() => {
      this.updateTimeSlide();
    }, 2000)

    //Catch theme color
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('theme')) {
      const theme_color = urlParams.get('theme');
      this.bgColor = theme_color;
      this.theme_color_gradient_right = 'linear-gradient(90deg,  ' + theme_color + ', #00800000)';
      this.theme_color_gradient_left = 'linear-gradient(270deg,  ' + theme_color + ', #00800000)';
    }


    ///END  


  },
  methods: {

    validate() {
      this.formValidation = true;
      var errorsHTMLList = '';
      if (this.requiredFields.length) {
        this.requiredFields.forEach((tab) => {
          if (tab.tab == this.activeTabSy) {
            if (tab.fields.length) {
              tab.fields.forEach((fieldGroup) => {
                if (fieldGroup.field.includes('.')) {
                  var groupVars = fieldGroup.field.split('.');
                  if (!this.inquiry_form[groupVars[0]][groupVars[1]] || this.inquiry_form[groupVars[0]][groupVars[1]] == '') {
                    errorsHTMLList += '<li>' + fieldGroup.label + '  field is required</li>';
                    if (this.$refs[fieldGroup.errorSpanRef]) {
                      this.$refs[fieldGroup.errorSpanRef].textContent = fieldGroup.label + ' field is required';
                    }
                    // document.getElementById(fieldGroup.errorSpanRef).innerText = fieldGroup.label + ' field is required';
                    this.formValidation = false;
                  }
                  else {
                    if (this.$refs[fieldGroup.errorSpanRef]) {
                      this.$refs[fieldGroup.errorSpanRef].textContent = '';
                    }
                  }
                }
                else {

                  if (!this.inquiry_form[fieldGroup.field] || this.inquiry_form[fieldGroup.field] == '' || this.inquiry_form[fieldGroup.field] == fieldGroup.defaultValue) {
                    errorsHTMLList += '<li>' + fieldGroup.label + '  field is required</li>';
                    if (this.$refs[fieldGroup.errorSpanRef]) {
                      this.$refs[fieldGroup.errorSpanRef].textContent = fieldGroup.label + ' field is required';
                    }
                    this.formValidation = false;
                  }
                  else {
                    if (this.$refs[fieldGroup.errorSpanRef]) {
                      this.$refs[fieldGroup.errorSpanRef].textContent = '';
                    }
                  }
                }
              })
            }
          }
        })
      }
      if (errorsHTMLList != '') {
        this.validationMessageAlert = !this.formValidation;
        setTimeout(() => {
          document.getElementById('errorsList').innerHTML = errorsHTMLList;
          document.getElementById('errorsList').focus();
        }, 100);

      }
      else {
        this.validationMessageAlert = false;
      }
      return this.formValidation;
    },
    addStop() {
      if (this.tab2 == "return") {
        this.inquiry_form.returnJourney.stops.push([]);
      } else {
        this.inquiry_form.firstJourney.stops.push([]);
      }
    },
    removeStop(index) {
      if (this.tab2 == "return") {
        if (this.inquiry_form.returnJourney.stops.length < 2) {
          this.inquiry_form.returnJourney.stops = [];
        }
        else {
          this.inquiry_form.returnJourney.stops = this.inquiry_form.returnJourney.stops.splice(index, 1);
        }
      } else {

        if (this.inquiry_form.firstJourney.stops.length < 2) {
          this.inquiry_form.firstJourney.stops = [];
        }
        else {
          this.inquiry_form.firstJourney.stops = this.inquiry_form.firstJourney.stops.splice(index, 1);
        }
      }
    },
    addItem() {
      // this.inquiry_form.extra_items.push([]);
      this.inquiry_form.deliverableItems['extra_items'].unshift({ name: '', qty: 0 });

    },
    removeItem(index) {
      if (this.inquiry_form.deliverableItems['extra_items'].length < 2) {
        this.inquiry_form.deliverableItems['extra_items'] = [{ name: '', qty: 0 }];
      }
      else {
        // this.inquiry_form.deliverableItems['extra_items'] = this.inquiry_form.deliverableItems['extra_items'].splice(index, 2);
        this.inquiry_form.deliverableItems['extra_items'] = this.inquiry_form.deliverableItems['extra_items'].filter((item, key) => {
          return key != index
        })

      }

    },
    updateTimeSlide(dd = this.inquiry_form.timeRange) {
      let newVal = '';
      let newVal1 = '';
      let newVal2 = '';

      if (dd[0] > 12) {
        newVal1 = (dd[0] - 12) + ":00 PM - ";
      }
      if (dd[1] > 12) {
        newVal2 = (dd[1] - 12) + ":00 PM";
      }

      if (dd[0] <= 12) {
        newVal1 = dd[0] + ":00 AM - ";
      }
      if (dd[1] <= 12) {
        newVal2 = dd[1] + ":00 AM";
      }


      newVal = newVal1 + newVal2;

      this.inquiry_form.timeRangeDisplay = newVal;

      setTimeout(() => {
        this.calculateFixedTimeArrivalPrice();
        this.calculatePrice();
      }, 500)

    },
    async getCompanyInfo() {
      await this.$axios
        .get(this.api + "getCompanyInfo")
        .then((response) => {
          this.companyInfo = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {

        });
    },
    async getJourneyTypes() {
      this.journeyTypesLoading = true;
      await this.$axios
        .get(this.api + "getJourneyTypes", { token: this.api_key })
        .then((response) => {
          this.journeyTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.journeyTypesLoading = false;
        });
    },

    async getClientCompanies() {
      this.ClientCompaniesLoading = true;
      await this.$axios
        .get(this.api + "getClientCompanies", { token: this.api_key })
        .then((response) => {
          this.clientCompanies = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.ClientCompaniesLoading = false;
        });
    },
    async getReviews() {
      this.ReviewsLoading = true;
      await this.$axios
        .get(this.api + "getReviews", { token: this.api_key })
        .then((response) => {
          this.reviews = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.ReviewsLoading = false;
        });
    },
    async getDeliverableItems() {

      this.inquiry_form.deliverableItems['extra_items'] = [];
      this.inquiry_form.deliverableItems['extra_items'].push({ name: '', qty: 0 })
      this.DeliverableItemsLoading = true;
      await this.$axios
        .get(this.api + "getDeliverableItems", { token: this.api_key })
        .then((response) => {
          if (response.data.length) {
            response.data.forEach((product) => {
              if (this.inquiry_form.deliverableItems[product.category]) {
                this.inquiry_form.deliverableItems[product.category].push({ name: product.name, qty: 0 });
              }
              else {
                this.inquiry_form.deliverableItems[product.category] = [];
                this.inquiry_form.deliverableItems[product.category].push({ name: product.name, qty: 0 });
              }

            })
          }
          this.inquiry_form.deliverableItems = { ...this.inquiry_form.deliverableItems };
          console.log(this.inquiry_form.deliverableItems);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.DeliverableItemsLoading = false;
        });
    },

    async getVehicles() {
      this.vehiclesLoading = true;
      var Vthis = this;
      await this.$axios
        .get(this.api + "getCompanyVehicles", { params: { distance: this.calculated_distance, suitcases: this.inquiry_form.suitcases, passengers: this.inquiry_form.passengers, hand_luggage: this.inquiry_form.hand_luggage, child_seats: this.inquiry_form.child_seats } })
        .then((response) => {
          this.vans = response.data;
          if (this.vans[0]) {
            setTimeout(() => {
              this.currentVehicleInSlider = 0;
              this.inquiry_form.vehicle_type_id = this.vans[0].vehicle_type_id;
              this.inquiry_form.van = this.vans[0].id;
            }, 1000)
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          Vthis.vehiclesLoading = false;
          // var infoText = 'Vehicle';
          // if (this.vans.length > 1) {
          //   infoText = 'Vehicles';
          // }
          // this.$refs['infoBox'].textContent = this.vans.length + ' ' + infoText;
        });
    },

    async calculateFixedTimeArrivalPrice() {
      this.calculatingFixedTimeArrivalPriceLoading = true;
      this.inquiry_form.api_key = this.$api_key;

      var Vthis = this;
      await this.inquiry_form
        .post(this.api + "calculateFixedTimeArrivalPrice", Vthis.inquiry_form)
        .then((response) => {
          this.calculatedFixedTimeArrivalPrice = 0;
          if (response.data.calculated_price && response.data.calculated_price > 0) {
            this.calculatedFixedTimeArrivalPrice = this.companyInfo['company_currency_code'] + ' ' + response.data.calculated_price;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.calculatingFixedTimeArrivalPriceLoading = false;
        })
    },

    async calculatePrice(must_run = false) {
      if (!this.calculatingPrice || must_run) {
        this.$refs['infoBox'].textContent = 'Calculating...';
        this.calculatingPrice = true;
        this.inquiry_form.api_key = this.$api_key;
        this.vans[this.currentVehicleInSlider].calculatedPrice = 0;
        var Vthis = this;
        await this.inquiry_form
          .post(this.api + "calculateOrderPriceFormWebFrom", Vthis.inquiry_form)
          .then((response) => {
            this.estimated_price = '';
            if (response.data.total_price && response.data.total_price > 0) {
              this.estimated_price = this.companyInfo['company_currency_code'] + ' ' + response.data.total_price;
            }
            this.vans[this.currentVehicleInSlider].calculatedPrice = this.estimated_price;
            this.$refs['infoBox'].textContent = this.estimated_price;
          })
          .catch((error) => {
            console.log(error);
            this.$refs['infoBox'].textContent = 'Error!'
          })
          .finally(() => {
            this.calculatingPrice = false;
          })
      }
    },

    async saveAndSendOrder() {
      if (this.validate()) {
        this.blockUI = true;
        this.inquiry_form.api_key = this.$api_key;
        this.validationMessageAlert = false;
        this.formValidation = true;
        var Vthis = this;
        await this.inquiry_form
          .post(this.api + "saveAndSendOrder", Vthis.inquiry_form)
          .then((response) => {
            if (response.data.success) {
              let invoice_data = JSON.parse(response.data.response);
              this.invoice_link = invoice_data.invoice_link;

              // console.log(invoice_data.invoice_link)
              // console.log(JSON.parse(response.data.response));
              this.orderSent = true;
              window.open(invoice_data.invoice_link, '_blank').focus();
            }
          })
          .catch((error) => {
            var errorsHTMLList = '';
            if (Object.keys(error.response.data.errors).length > 0) {
              for (const field in error.response.data.errors) {
                errorsHTMLList += '<li>' + error.response.data.errors[field][0] + '</li>';
              }
              Vthis.validationMessageAlert = true;
              Vthis.formValidation = false;
              setTimeout(() => {
                document.getElementById('errorsList').innerHTML = errorsHTMLList;
                document.getElementById('errorsList').focus();
              }, 100)
            }
          })
          .finally(() => {
            this.blockUI = false;
          })
      }
    },


    loadRoutesMapView(interval = 1000, showMap = true) {
      // alert(journeyType); 
      // console.log(this.inquiry_form.firstJourney);
      if (this.inquiry_form.firstJourney.start_location) {
        var pickUpPoint = this.inquiry_form.firstJourney.start_location;
        var dropOfPoint = this.inquiry_form.firstJourney.destination;
        setTimeout(() => {
          const directionsService = new window.google.maps.DirectionsService();
          const directionsRenderer = new window.google.maps.DirectionsRenderer();
          if (showMap) {
            const map = new window.google.maps.Map(
              document.getElementById("routesMapViewContainer"),
              {
                zoom: 6,
                center: { lat: 41.85, lng: -87.65 },
              }
            );

            directionsRenderer.setMap(map);
          }
          // this.calculateAndDisplayRoute(directionsService, directionsRenderer);
          var queryString = {};
          var stops = [];

          queryString.origin = {
            lat: pickUpPoint.latitude,
            lng: pickUpPoint.longitude,
          };
          queryString.destination = {
            lat: dropOfPoint.latitude,
            lng: dropOfPoint.longitude,
          };
          // queryString.destination = {lat : journeyRoutes[journeyRoutes.length-1].latitude, lng : journeyRoutes[journeyRoutes.length-1].longitude};
          if (this.inquiry_form.firstJourney.stops.length > 0) {
            for (var k = 0; k < this.inquiry_form.firstJourney.stops.length; k++) {
              if (this.inquiry_form.firstJourney.stops[k] && this.inquiry_form.firstJourney.stops[k].latitude) {
                stops.push({
                  location: {
                    lat: this.inquiry_form.firstJourney.stops[k].latitude,
                    lng: this.inquiry_form.firstJourney.stops[k].longitude,
                  },
                  stopover: true,
                });
              }
              else {
                this.inquiry_form.firstJourney.stops.splice(k, 1);
              }
            }

            if (stops.length) {
              queryString.stops = stops;
            }
          }
          // console.log(queryString);
          this.calculateAndDisplayRoute(directionsService, directionsRenderer, queryString);
        }, interval);
      }
    },
    calculateAndDisplayRoute(directionsService, directionsRenderer, queryString) {
      var Vthis = this;
      // var journeyRoutes = this.inquiry_form.firstJourney;
      directionsService
        .route({
          origin: queryString.origin,
          destination: queryString.destination,
          waypoints: queryString.stops,
          optimizeWaypoints: true,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          // console.log(journeyType);
          // console.log(response);
          directionsRenderer.setDirections(response);
          const route = response.routes[0];
          // var journeyIndex = 0;
          // var distance = 0;
          // var totalDistance = 0;
          var totalDistanceMeters = 0;
          var totalTravelingTime = 0;
          // var totalDistanceWithOutUnit = 0;
          // journeyRoutes[0].distance = '';
          // journeyRoutes[0].time = '';
          Vthis.inquiry_form.firstJourney.calculations = [];
          route.legs.forEach((leg) => {

            totalDistanceMeters = totalDistanceMeters + leg.distance.value;
            totalTravelingTime = totalTravelingTime + leg.duration.value;

            // if(this.defaultDistanceUnit == 'mile'){
            //     distance = ( leg.distance.value * 0.000621371192 ).toFixed(0) + ' mile';
            // }
            // journeyRoutes[journeyIndex].distance = distance;
            // journeyRoutes[journeyIndex].time = leg.duration.text;
          });

          var totalDistanceMetersCalculated = 0;
          if (this.companyInfo.distance_unit == 'mile') {
            this.calculated_distance = (totalDistanceMeters * 0.000621371192).toFixed(0);
            totalDistanceMetersCalculated = this.calculated_distance + ' mile';
          }
          else if (this.companyInfo.distance_unit == 'nmi') {
            this.calculated_distance = (totalDistanceMeters / 1852).toFixed(0);
            totalDistanceMetersCalculated = this.calculated_distance + ' nmi';
          }
          else {
            this.calculated_distance = (totalDistanceMeters / 1000).toFixed(0);
            totalDistanceMetersCalculated = this.calculated_distance + ' KM';
          }

          var totalTravelingTimeCalculated = Math.floor(totalTravelingTime / 3600) + ' hours ' + Math.floor(totalTravelingTime % 3600 / 60) + ' minutes';
          Vthis.inquiry_form.firstJourney.calculations.push({ distance: totalDistanceMetersCalculated, time: totalTravelingTimeCalculated, RawDistanceMeters: totalDistanceMeters, RawTimeSeconds: totalTravelingTime });
          //console.log(Vthis.inquiry_form.firstJourney);
          // // Time is in seconds so need to convert it into hours and minutes
          // totalTravelingTime = Math.floor(totalTravelingTime / 3600) + ' hours ' + Math.floor(totalTravelingTime % 3600 / 60) + ' minutes';

          // this.inquiry_form.firstJourney = journeyRoutes;



        })
        .catch((e) => {
          console.log(e);
          // window.alert("Directions request failed due to " + status)
        });
    },
    selectVan(vanId) {

      this.selectedVan = this.vans.filter((v) => {
        return v.id == vanId;
      });

      this.vans.forEach((v, i) => {
        if (this.selectedVan[0].id == v.id) {
          this.currentVehicleInSlider = i;
        }
      });
      // console.log(this.selectedVan);
      this.inquiry_form.van = this.selectedVan[0].id;
      this.inquiry_form.vehicle_type_id = this.selectedVan[0].vehicle_type_id;
      // this.currentVehicleInSlider = 
    },
    setPaymentMethod(value) {
      this.paymentMethod = value;
    },
    sunny() {
      if (this.inquiry_form.firstJourney.enReturn == false) {
        this.tab2 = 1;
      } else {
        this.tab2 = 0;
      }
    },
    getAutocompleteData(autocomplete) {
      var place = autocomplete; //.getPlace();
      console.log(place);
      var placeDetails = {
        address: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
        latitude: "",
        longitud: "",
      };
      let postcode = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http:/Contact Name/goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            placeDetails.address = component.long_name
              ? component.long_name
              : "";
            break;
          }

          case "route": {
            placeDetails.address += component.short_name;
            break;
          }

          case "postal_code": {
            placeDetails.zipcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            placeDetails.zipcode = `${postcode}-${component.long_name}`;
            break;
          }
          case "locality":
            placeDetails.city = component.long_name;
            break;
          case "administrative_area_level_1": {
            placeDetails.state = component.short_name;
            break;
          }
          case "country":
            placeDetails.country = component.long_name;
            break;
        }
      }

      if (place.formatted_address) {
        placeDetails.address = place.formatted_address;
      }

      if (place.geometry) {
        var locationLATLNG = JSON.parse(
          JSON.stringify(place.geometry.location)
        );
        placeDetails.latitude = locationLATLNG.lat;
        placeDetails.longitude = locationLATLNG.lng;
      }

      return placeDetails;
    },

    Pick_setPlace(placeDetails) {
      if (this.tab2 == "return") {
        this.inquiry_form.returnJourney.start_location =
          this.getAutocompleteData(placeDetails);
      } else {
        this.inquiry_form.firstJourney.start_location =
          this.getAutocompleteData(placeDetails);
      }

    },
    // datetime(){
    //   return moment(inquiry_form.firstJourney.pi_date).format('MMMM Do YYYY, h:mm:ss a');
    // },
    stop_setPlace(index, placeDetails) {
      // this.inquiry_form.stops = e.formatted_address;
      // console.log(document.querySelector('#input-first-stops-'+index));
      // document.querySelector('#input-first-stops-'+index)[0].value = this.inquiry_form.stops[index].address;
      // alert(document.querySelector('#input-first-stops-'+index)[0].value);
      // console.log(this.inquiry_form.stops);
      if (this.tab2 == "return") {
        this.inquiry_form.returnJourney.stops[index] =
          this.getAutocompleteData(placeDetails);
      } else {
        this.inquiry_form.firstJourney.stops[index] =
          this.getAutocompleteData(placeDetails);
      }
    },
    drop_setPlace(placeDetails) {
      if (this.tab2 == "return") {
        this.inquiry_form.returnJourney.destination =
          this.getAutocompleteData(placeDetails);
      } else {
        this.inquiry_form.firstJourney.destination =
          this.getAutocompleteData(placeDetails);
      }
    },
    time(time) {
      this.inquiry_form.pi_date = time;
    },

    next() {
      if (this.validate()) {

        // this.loading[i] = true
        // setTimeout(() => (this.loading[i] = false), 3000);
        // setTimeout(() => (this. = +this. + 1), 2000);
        this.activeTabSy = +this.activeTabSy + 1;
        if (this.activeTabSy === 1) {
          this.loadRoutesMapView();
          this.getVehicles();

          // this.calculatePrice();
        }
        else if (this.activeTabSy === 2) {
          this.$refs['infoBox'].textContent = '';
          this.calculatePrice();
        }
        else if (this.activeTabSy === 3) {
          this.inquiry_form.timeRange = [9, 13];
          this.calculateFixedTimeArrivalPrice();
        }
        // console.log(this.inquiry_form);
      }

      this.smPanel = this.activeTabSy - 1;
    },
    back() {
      this.activeTabSy -= 1;
      if (this.activeTabSy === 1) {
        this.loadRoutesMapView();
        // this.getVehicles();
        setTimeout(() => {
          // this.calculatePrice();
        }, 1000);
        // this.currentVehicleInSlider = this.selectedVan
      }
      else if (this.activeTabSy === 2) {
        // this.$refs['infoBox'].textContent = '';
        // this.calculatePrice();
      }
      else if (this.activeTabSy === 3) {
        this.calculateFixedTimeArrivalPrice();
      }

      this.smPanel = this.activeTabSy - 1;
    },
    close() {
      this.activeTabSy = 0;
    },
    reload() {
      window.location.reload();
    },
  },
  watch: {

    'inquiry_form.extra_hours': {
      handler() {
        this.calculatePrice();
      },
    },
    'currentVehicleInSlider': {
      handler(newValue) {
        // if (!this.vans[newValue].calculatedPrice || this.vans[newValue].calculatedPrice < 1) {
        this.inquiry_form.van = this.vans[newValue].id;
        this.inquiry_form.vehicle_type_id = this.vans[newValue].vehicle_type_id;

        // }
      }
    },
    'inquiry_form.helpers': {
      handler(newValue) {
        if (newValue == 1) {
          this.helpersSuffix = ' (driver)';
        }
        else {
          this.helpersSuffix = '';
        }
        if (this.estimated_price != '') {
          this.calculatePrice();
        }
      },
    },
    'inquiry_form.pi_date': {
      handler() {
        this.calculatePrice();
      },
    },
    'inquiry_form.pickup_floor': {
      handler() {
        if (this.estimated_price != '') {
          this.calculatePrice();
        }
      },
    },
    'inquiry_form.dropof_floor': {
      handler() {
        if (this.estimated_price != '') {
          this.calculatePrice();
        }
      },
    },
    'inquiry_form.pickup_lift': {
      handler() {
        if (this.estimated_price != '') {
          this.calculatePrice();
        }
      },
    },
    'inquiry_form.dropof_lift': {
      handler() {
        if (this.estimated_price != '') {
          this.calculatePrice();
        }
      },
    },
    'inquiry_form.vehicle_type_id': {
      handler() {
        // if (this.estimated_price != '') {
        this.calculatePrice();
        // }
      },
    },
    'calculated_distance': {
      handler() {
        if (this.calculated_distance != '') {
          // this.getVehicles();
        }
      },
    },

  }
};
</script>
 

<style >
button.v-btn.v-slide-group-item--active.v-tab--selected.v-theme--light.v-btn--density-default.rounded-0.v-btn--size-default.v-btn--variant-text.v-tab {
  background: #E4E4E4;
  color: #000;
}

.width_td_items {
  width: 60% !important;
}

.p-0 td {
  padding: 10px 0px !important;
}

.tabel_heading th {
  font-size: 18px;
}

.table_list tr:nth-child(even) {
  background-color: #0000000d;
}

/* Map input */
input#input-17 {
  padding: 10px 0px 10px 10px;
}

.map-icon.v-field__prepend-inner {
  padding-top: 15px;
}

/* Edit frome work */
.li.carousel__slide.carousel__slide--visible.carousel__slide--next {
  min-height: 500px !important;
}

span.v-chip.v-theme--light.v-chip--density-default.v-chip--size-default.v-chip--variant-text.ma-2 {
  margin: 0px !important;
  padding: 10px !important;
  border-bottom: 1px solid !important;
}

span.v-chip__underlay {
  background-color: #ffffff !important;
}

.carousel__icon {
  width: 90px !important;
  height: 30px !important;
  fill: #ffffff !important;
  border-radius: 100% !important;
  background-color: #000000 !important;
}

td {
  height: 30px !important;
  font-size: 15px !important;
}

#routesMapViewContainer {
  width: 100%;
  height: 250px;
}

.bg-img-tab-1 {
  width: 100%;
  height: 100vh;
  /* background-image: url('/src/assets/img/car.jpg'); */
  background-size: cover;
}

.bg-img-tab-1 {
  background-color: #ffffff;
  width: auto;
  height: auto;
}

.v-card .v-card-text {
  border: 1px solid #d8d8d8;
}

.v-col-sm-12.v-col-md-6.v-col.order-last.d-block {
  background-image: url('/src/assets/img/bakaavans.png');
  background-repeat: round;
}

.div-col {
  border-bottom: 1px solid #ccc;
}

.custome-btn {
  font-size: 25px !important;
  padding: 15px !important;
  line-height: 1px !important;

}

.separator {
  border: 3px solid #fff;
  height: 2px;
  margin: 5px 0px;
  border-radius: 50%;
}

.div_service {
  box-shadow: rgba(0, 0, 0, 0.482) 0px 5px 15px;
  padding: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  margin: 5px;
  text-align: left;
  max-height: 100px !important;
  min-height: 100px !important;
  overflow: hidden;
}



.fixed-content {
  text-align: justify;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Stat Of Reviews Css By Sunny */
.div_testimonial {
  box-shadow: rgba(0, 0, 0, 0.482) 0px 5px 15px;
  padding: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  margin: 10px;
  text-align: left;
  max-height: 300px !important;
  min-height: 150px !important;
  overflow: hidden;
}

.div_testimonial .profile_image.testimonial_img {
  border-radius: 100px;
}

/* Star Rating */
.startrations {
  /* display: flex; */
  text-align: right;
}

/* Carosel */
.testimonial section.carousel .carousel__viewport ol.carousel__track .carousel__slide {
  min-height: 100% !important;
}


/*End Of Reviews Css By Sunny */
.v-alert.v-alert--border.v-alert--border-start.v-theme--light.text-red.v-alert--density-default.v-alert--variant-tonal {
  POSITION: fixed;
  top: 0;
  width: 46% !important;
  z-index: 200;
  background-color: #FDE8E6;
  padding: 5px 20px !important;
  margin: 5px !important;
}

.coolText {
  font-size: 12px;
  padding: 10px;
  text-transform: capitalize;
  font-style: oblique;
}

.overflow-y-scroll {
  overflow-y: auto;
}


.testimonial section.carousel .carousel__prev {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  background: v-bind(theme_color_gradient_right) !important;
  display: flex;
  margin: 0;
  width: 5% !important;
  padding: 5px;
  justify-content: flex-start;
}

.testimonial section.carousel .carousel__next {
  height: 100% !important;
  max-height: 100% !important;
  background: v-bind(theme_color_gradient_left) !important;
  display: flex;
  margin: 0px;
  width: 5% !important;
  padding: 5px;
  justify-content: flex-end;
}

.padding {
  padding-top: 50px !important;
  padding-right: 30px !important;
}

.masonry {
  column-count: 2;
  /* Adjust the number of columns as desired */
  column-gap: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
  /* Adjust the gap between columns */
}

.masonry-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  border: 3px solid #fff;
  /* Adjust the gap between items */
}

.masonry-item img {
  width: 100%;
  height: auto;
}

.review-company-logo {
  width: 40px;
  display: block;
  position: absolute;
  margin-top: 58px;
  right: 10px;
}
</style>
