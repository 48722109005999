<template>
  <MultiStep />
  <!-- <HelloWorld /> -->
</template>

<script>
import MultiStep from "./components/MultiStep.vue";
// import HelloWorld from './components/HelloWorld.vue';

export default {
  name: "App",
  components: {
    MultiStep,
    // HelloWorld
  },
  computed: {
    btnStyles() {
      return {
        "background-color": this.bgColor,
        color: this.color,
        height: `${this.height}px`,
      };
    },
  },
  data() {
    return {
      bgColor: "red",
      color: "white",
      sunny: "98,0,238",
    };
  },
};
</script>

<style>
/* NEW CSS */
a {
  text-decoration: none;
}

.width150px {
  width: 150px;
}

.v-text-field .v-input__details {
  display: none;
}

.no-padding {
  padding: 12px 12px 12px 12px !important;
}

.carousel__slide {
  min-height: 500px !important;
}

.v-expansion-panel {
  margin: 0px 0px 10px 0px !important;
}

.car-card {
  max-width: 350px !important;
  min-width: 350px !important;
  min-height: 450px !important;
  position: relative !important;
  padding: 20px;
  margin: 0px 0px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.card-detail p {
  margin: 0px 10px 10px 0px;
  line-height: 1;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myTheme {
  background-color: v-bind(color);
  color: white !important;
}

.selected-car {
  min-height: 400px !important;
}

.card-img {
  max-width: 160px !important;
  width: 160px;
}

.car-card-btn {
  padding: 8px 0px;
  width: 100% !important;
  border-radius: 0px;
  box-shadow: rgba(31, 116, 255, 0.12) 0px 5px 5px,
    rgba(31, 116, 255, 0.12) 0px -12px 30px,
    rgba(31, 116, 255, 0.12) 0px 4px 6px, rgba(31, 116, 255, 0.17) 0px 12px 13px,
    rgba(31, 116, 255, 0.09) 0px -3px 5px;
}

/* Card End */
.back-btn {
  margin-bottom: -18px;
}

.width-530px {
  width: 460px !important;
}

.width-500px {
  width: 420px !important;
}

.v-text-field__suffix {
  position: absolute;
  left: 35px;
}

.align-bootom {
  /* position: absolute !important; */
  margin: 25px;
  bottom: 0 !important;
  left: 0 !important;
}

body {
  overflow: hidden;
}

html {
  overflow: auto !Important;
}

.v-window__container {
  position: static !important;
  height: auto !important;
}

.display_none {
  display: none;
}

.display-none-lg {
  display: none;
}

.next {
  width: 300px;
}

.flex-align-start {
  justify-content: flex-start !important;
  background-color: rgb(var(--v-theme-primary)) !important;
}

.activeChild button:not(:disabled) {
  color: #1f74ff;
}

.carousel__icon {
  fill: #ffffff !important;
  background-color: #000000 !important;
  border-radius: 2px;
}

.form-card {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  padding-left: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-stop {
  float: right !important;
  font-size: 12px !important;
  padding: 5px 8px !important;
  margin: -50px 49px 0px 0px;
}

.remove-stop {
  color: #ffffff !important;
  float: right !important;
  font-size: 14px !important;
  border-radius: 77px !important;
  padding: 3px 9px !important;
  margin: -52px 50px 0px 0px;
}

.input-width,
.input-select-con,
.con-select {
  width: 420px !important;
}

.input-width-add {
  width: 308px !important;
}

.input-width-remove {
  width: 350px !important;
}

.vs-input--input,
input.input-select.vs-select--input,
.con-select {
  height: 40px !important;
}

label {
  display: block;
  color: #4c4c4c;
  font-size: 13.6px;
  font-weight: 400;
  font-family: "Times New Roman";
  margin-left: 5px;
}

/*-Width-*/
.width-40px {
  width: 40px;
}

.px340 {
  width: 420px;
}

.width-800 {
  width: 800px !important;
}

/*-Display-*/
.inline-block-sy {
  display: inline-block;
}

.inline-flex {
  display: inline-flex !important;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

/*-Font-*/
.ft-24 {
  font-size: 16px;
}

.text-center {
  text-align: center;
}

.cur-sy {
  font-size: 16px;
}

.cur-sy span {
  font-size: 32px;
}

.colorx {
  background-color: #1f74ff !important;
  background: #1f74ff !important;
}

.number-label {
  margin: 8px 5px 0px 0px;
}

.mn-t-25px {
  margin-top: -25px;
}

/*-Alignment-*/
.vertical-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center-align {
  flex-direction: row;
  justify-content: center;
}

/*-Margin And Padding-*/
.px10 {
  margin-left: 10px !important;
}

.m-t-25px {
  margin-top: 25px;
}

.m-t-30px {
  margin-top: 30px;
}

.m-t-15px {
  margin-top: 15px;
}

.padding-15px {
  padding: 15px;
}

.padding-x-40px {
  padding: 0px 40px;
}

i.vs-checkbox--icon.material-icons.null.vs-checkbox--icon.vs-icon.notranslate.icon-scale.vs-checkbox--icon {
  font-size: 15px !important;
}

pre {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
}

.vue-slider-process {
  background-color: rgb(31, 116, 255) !important;
}

.vue-slider-dot-tooltip-inner {
  display: none;
}

.card-sy {
  width: 550px !important;
  cursor: pointer;
  border: 5px double rgb(31, 116, 255);
  box-shadow: rgba(31, 116, 255, 0.25) 0px 30px 60px -12px,
    rgba(31, 116, 255, 0.3) 0px 18px 36px -18px;
}

.card-title-sy {
  font-size: 24px;
  color: rgb(31, 116, 225);
  text-align: center;
}

.card-title-sy h3 {
  text-transform: uppercase;
}

.float-left {
  float: left !important;
}

li {
  list-style: none;
}

.card-img-2-sy {
  width: 390px;
  padding-top: 8%;
}

span.normal.input-span-placeholder.vs-input--placeholder {
  top: 15%;
}

.con-vs-popup .vs-popup {
  width: 800px !important;
}

.time_range_box {
  border: 0px;
  background: #ffffff00;
  font-size: 18px;
}

.w-h-100 {
  min-height: 100vh;
  min-width: 100vw;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p,
h4,
h3,
h2,
h1,
span {
  font-family: Arial !important;
}

input,
select,
option,
label,
span {
  font-family: Arial !important;
}

.align-right {
  flex-direction: row;
  justify-content: end;
}

.align-left {
  flex-direction: row;
  justify-content: start;
}

.user-bio-sy {
  width: 482px;
}

.name-card {
  box-shadow: rgba(31, 117, 255, 0.582) 0px 4px 16px,
    rgba(31, 116, 255, 0.05) 0px 8px 32px;
  border-radius: 5px;
  padding: 15px;
  margin: 0px 10px 0px 0px;
}

.primary {
  color: #1f74ff !important;
}

.heading {
  color: #ffffff;
  background-color: #1f74ff;
  border-radius: 5px;
  padding: 10px 0px;
}

.label-input {
  padding: 0px;
  margin: 0px !important;
  font-size: 16px;
  color: #1b355d;
}

.vuesax-app-is-ltr .vs-input--label,
.vuesax-app-is-ltr .vs-select--label {
  padding: 0px 0px 2px 0px !important;
  margin: 0px !important;
}

.display-none-sy .con-vs-tabs .con-ul-tabs {
  display: none !important;
}

.display-flex-sy .con-vs-tabs .con-ul-tabs {
  display: block !important;
}

.vuesax-app-is-ltr .vs-input--placeholder,
.vs-input--input.hasIcon {
  padding-left: 40px !important;
}

.vs-list--title {
  font-size: 14px;
}

.paypal-display-none {
  display: none !important;
}

.border-bottom {
  border-bottom: 2px solid green !important;
}

.book-now {
  background-color: #3419a7 !important;
}

.paypal {
  background-color: #3b7bbf !important;
}

.bank-transfer {
  background-color: #090a89 !important;
}

.credit-card {
  background-color: #3b556e !important;
}

.loading {
  width: 100%;
  height: 70vh;
  text-align: center;
}

#errorsList li {
  list-style: decimal;
  list-style-position: inside !important;
}

.error {
  color: red;
}

.no-data {
  text-align: center;
  padding: 30px 0px;
}

@media screen and (max-width: 1024) {
  img.card-img {
    width: 160px !important;
  }
}

@media screen and (max-width: 720px) {
  .width-500px {
    width: auto !important;
  }

  .selected-car {
    min-height: 375px !important;
    max-height: 380px !important;
  }

  .mb-16px {
    font-size: 16px;
  }

  .vs-list--title {
    font-size: 12px;
  }

  .back-btn b {
    display: none;
  }

  .mb-14px {
    font-size: 14px;
  }

  .v-col-6 {
    padding: 0px 0px 0px 20px !important;
  }

  .v-expansion-panel-text__wrapper {
    padding: 10px !important;
    border: 1px solid #e5e5e5;
    border-radius: 0px 0px 4px 4px !important;
  }

  .v-table__wrapper {
    overflow: hidden;
    height: auto !important;
    max-height: 483px !important;
  }



  .form-card {
    width: 100vw;
    padding-left: 15px;
    margin-right: -20px !important;
  }

  .car-card {
    max-width: 360px !important;
    min-width: 285px !important;
    min-height: 440px !important;
    padding: 0px 0px;
    margin: 0px 0px;
  }

  .input-width,
  .input-select-con,
  .time_range_box,
  .time_range_width,
  .mb-input-width {
    width: 68vw !important;
  }

  .mb_m-t-5px {
    margin-top: 20px;
  }

  .m-t-25px {
    margin-top: 10px;
  }

  .m-t-15px {
    margin-top: 5px;
  }

  .mb_margin-60px {
    margin-top: 60px;
  }

  .label-input {
    font-size: 14px;
  }

  .padding-x-40px {
    padding: 0px !important;
  }

  .inline-flex {
    display: block !important;
  }

  .inline-block-sy {
    display: block !important;
  }

  .user-bio-sy {
    width: 76vw !important;
  }

  .mb_input-item {
    width: 30vh;
  }

  .card-img-2-sy {
    width: 160px;
    padding-top: 0%;
  }

  .padding-15px {
    padding: 5px;
  }

  .margin-t-10px {
    margin-top: 10px;
  }

  .con-select {
    width: 200px !important;
  }

  .display-none-mb {
    display: none !important;
  }

  .add-stop b {
    display: none;
  }

  .add-stop {
    margin: -50px 10px 0px 0px;
    border-radius: 100% !important;
  }

  .remove-stop {
    margin: -50px 10px 0px 0px;
    border-radius: 100% !important;
  }
}
</style>
